import { useMutation, useQuery } from "@tanstack/react-query";
import { genererMotDePasse, removeUnnecessaryHTMLStuff } from "../../../utils/utilities/constants";
import React, { useEffect, useMemo, useRef, useState } from "react";

import useProduct from '../../../utils/utilities/hooks/useProduct';
import useCategory from '../../../utils/utilities/hooks/useCategory';
import useBrand from '../../../utils/utilities/hooks/useBrand';
// components
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import dateToLocalString from "../../../utils/utilities/dateToLocalString";
import AsyncSelect from "react-select/async";
import $ from 'jquery'
import ReactQuill, {Quill} from 'react-quill';
import QuillResizeImage from 'quill-resize-image';
import BlotFormatter from 'quill-blot-formatter';
import { Riple, TrophySpin } from "react-loading-indicators";
import useImage from "../../../utils/utilities/hooks/useImage";

let timer = null;
const Video = Quill.import('formats/video');
const Link = Quill.import('formats/link'); 

class CoustomVideo extends Video {
  static create(value) {
    const node = super.create(value);
    
    const video = document.createElement('video')
    video.setAttribute('controls', true);
    video.setAttribute('type', "video/mp4");
    video.setAttribute('style', "height: 100%; width: 100%");
    video.setAttribute('src', this.sanitize(value));
    node.appendChild(video);

    return node;
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }
};

CoustomVideo.blotName = 'video';
CoustomVideo.className = 'ql-video';
CoustomVideo.tagName = 'iframe';

Quill.register('formats/video', CoustomVideo);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register("modules/resize", QuillResizeImage);
export default function ProductForm() {

    $("input[data-type='currency']").on({
      keyup: function() {
        formatCurrency($(this));
      },
      blur: function() { 
        formatCurrency($(this), "blur");
      }
    });


    function formatNumber(n) {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }


    function formatCurrency(input, blur) {
      // appends $ to value, validates decimal side
      // and puts cursor back in right position.
      
      // get input value
      var input_val = input.val();
      
      // don't validate empty input
      if (input_val === "") { return; }
      
      // original length
      var original_len = input_val.length;

      // initial caret position 
      var caret_pos = input.prop("selectionStart");
        
      // check for decimal
      if (input_val.indexOf(".") >= 0) {

        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(".");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);

        // validate right side
        right_side = formatNumber(right_side);
        
        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
          right_side += "00";
        }
        
        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = left_side + "." + right_side;

      } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);
        input_val = input_val;
        
        // final formatting
        if (blur === "blur") {
          input_val += ".00";
        }
      }
      
      // send updated string to input
      input.val(input_val);

      // put caret back in the right position
      var updated_len = input_val.length;
      caret_pos = updated_len - original_len + caret_pos;
      input[0].setSelectionRange(caret_pos, caret_pos);
    }

    const quillRef = useRef(null);
    const navigate =  useNavigate()

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [specifications, setSpecifications] = useState("");
    const [category_level_one, setCategorylevelone] = useState(null);
    const [category_level_two, setCategoryleveltwo] = useState(null);
    const [category_level_three, setCategorylevelthree] = useState(null);
    const [model, setModel] = useState("");
    const [status, setStatus] = useState("in-stock");
    const [price, setPrice] = useState(0);
    const [stock, setStock] = useState(1);
    const [active, setActive] = useState(true);
    const [loadImageUpload, setLoadImageUpload] = useState(false);
    const [images, setImages] = useState([]);
    const [promotion, setPromotion] = useState({
      active: false,
      cost: 0,
      start_dt: moment(dateToLocalString(new Date()), 'DD/MM/YYYY hh:mm').format("YYYY-MM-DD[T]HH:mm"),
      end_dt: moment(dateToLocalString(new Date()), 'DD/MM/YYYY hh:mm').add('days', 7).format("YYYY-MM-DD[T]HH:mm")
    })
    const [charCount, setCharCount] = useState(0)
    const [currentData, setCurrentData] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [brand, setbrand] = useState(null)
    const {id} = useParams()
    let { client } = useProduct()
    let { client: categoryClient } = useCategory()
    let { client: brandClient } = useBrand()
    let { client: imageClient } = useImage()

    useEffect(() => {
      if(currentData) {
        setEditMode(true)
        setTitle(currentData.title)
        setDescription(currentData.description)
        setSpecifications(currentData.specifications)
        setCategorylevelone(currentData.category_level_one)
        setCategoryleveltwo(currentData.category_level_two)
        setCategorylevelthree(currentData.category_level_three)
        setModel(currentData.model)
        setStatus(currentData.status)
        setPrice(currentData.price)
        setStock(currentData.stock)
        setActive(currentData.active)
        setImages(currentData.images)
        setPromotion(currentData.promotion)
        setbrand(currentData.brand)
        setCharCount(removeUnnecessaryHTMLStuff(currentData.specifications)?.length)
      }
      
    }, [currentData])


    useQuery({
      queryKey: ['product-data', id],
      queryFn: async () => {
          if(id) {
            let result = await client.getProductById(id)
            setCurrentData(result)
          }
      }
    })

    const {
      data: CategoryData
    } = useQuery({
      queryKey: ["categories-data"],
      queryFn: async () => {
        let result = await categoryClient.getAllCategories({ page: 1, limit: 10})
        return result;
      },
      keepPreviousData: true
    });


    const mutation = useMutation({
      mutationFn: async () => {
        if(editMode && currentData) {
          return await client.updateProduct(currentData._id, {
            title,
            description,
            specifications,
            category_level_one,
            category_level_two,
            category_level_three,
            brand,
            model,
            price,
            promotion,
            images,
            active,
            status,
            stock
          });
        }else {

          return await client.createProduct({
            title,
            description,
            specifications,
            category_level_one,
            category_level_two,
            category_level_three,
            brand,
            model,
            price,
            promotion,
            images,
            active,
            status,
            stock
          });
        }
      },
      onSuccess: (data, variables, context) => {
        toast.success( 'Produit enregistré avec succès !', {position: 'bottom-center'})

        setTimeout(() => {
          navigate('/products')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });

    const deleteItem = useMutation({
      mutationFn: async () => {
        return await client.deleteProduct(currentData._id);
      },
      onSuccess: (data, variables, context) => {
        toast.success( 'Bannière supprimée avec succès !', {position: 'bottom-center'})
        setTimeout(() => {
          navigate('/products')
        }, 500);
      },
      onError: (data, error, variables, context) => {
        toast.error(data?.message , {position: 'bottom-center'})
      },
    });


    const handleFileInputChange = async (e) => {
      const files = e.target.files;
      setLoadImageUpload(true)
      try {
        const result = await imageClient.uploadImage({image: files[0], folder: 'products'})
        setImages((old) => [...old, result]);
      } catch (error) {
        toast.error('Veuillez réessayer avec une image de moins de 1mb' , {position: 'bottom-center'})
        
      }
      setLoadImageUpload(false)
    };

    const loadOptions = (inputValue, callback) => {
      if (timer) clearTimeout(timer)
      timer = setTimeout(async () => {
        handleLoadOptions(inputValue, callback);
      }, 1000)
    }

    const handleLoadOptions = (inputValue, callback) => {
      if (inputValue?.length > 0) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(async () => {
          brandClient
            .getAllBrands({ search: inputValue, page: 1, limit: 10 })
            .then((response) => {
              callback(
                transformArrayForBrandOptions(response?.results || [])
              );
            })
            .catch((error) => {
              callback([]);
              console.error(error);
            });
        }, 1000);
      }
    };

    function transformArrayForBrandOptions(
      arr
    ) {
      if (arr && arr?.length > 0) {
        return arr?.map((item, index) => ({
          value: item?._id,
          label: item?.name
        }));
      } else {
        return [];
      }
    }

    const onChangeDate = (type, e) => {
      let dt = new Date(e.target.value).getTime();
      let current_timestamp = dt
      let seconds = 0
      if (type === 'start') {
        return e.target.value;
      } else {
        if (promotion.start_dt) {
          seconds = (current_timestamp - promotion.start_dt)
          if (seconds < 0) {
            alert('Impossible de choisir une date arrière')
          } else {
            return e.target.value;
          }
        }
      }
    }

    const modules = {
      toolbar: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }]
      ]
    }
  
  
    const modulesDescription = useMemo(() => ({
      resize: {
        locale: {},
      },
      toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              [{ header: 1 }, { header: 2 }], // custom button values
              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, false] }],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ direction: 'rtl' }], // text direction
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ['link', 'image', 'video'],
              ['clean'],
            ],
            handlers: {
              image: () => {
                  imageHandler();
              }
            }
          },
    }), [])
  
    const formats = [
      'background',
      'bold',
      'color',
      'font',
      'code',
      'italic',
      'link',
      'size',
      'strike',
      'script',
      'underline',
      'blockquote',
      'header',
      'indent',
      'list',
      'align',
      'direction',
      'code-block',
      'image',
      'video',
    ];

    const specificationsChange = (content, delta, source, editor) => {
      setCharCount(editor.getLength() - 1)
      setSpecifications(editor.getHTML())
    }
    const descriptionChange = (value) => {
      setDescription(value)
    }

    const uploadFiles = async (filename) => { 
      const editor = quillRef.current.getEditor();
        const res = filename;  
        editor.insertEmbed(editor.getSelection(), "image", res);
    } 

    const  imageHandler = async () => {  
    
      const input = document.createElement('input');  
      input.setAttribute('type', 'file');  
      input.setAttribute('accept', 'image/*');  
      input.click();  
    
      input.onchange = async () => { 
        const files = input.files;
        try {
          const result = await client.uploadDescriptionImage({image: files[0]})
          uploadFiles(result?.url)
        } catch (error) {
          toast.error('Veuillez réessayer avec une image de moins de 1mb' , {position: 'bottom-center'})
          setLoadImageUpload(false)
        }
      };  
    } 

    const removeImage = async (image) => {
      setLoadImageUpload(true)
      try {
          await imageClient.deleteImage(image.public_id)
          const remainImages = images.filter(im => im?.public_id !== image.public_id)
          setImages(remainImages)
      } catch (error) {

        toast.error('Veuillez réessayer' , {position: 'bottom-center'})
        
      }
      setLoadImageUpload(false)
    };
  
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold"> {!editMode ? 'Nouveau produit' : `Modifier le produit`} </h6>
            <button
                className="bg-gray-500 text-white active:bg-gray-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  navigate('/products')
                }}
              >
                <i class="fas fa-solid fa-arrow-left-long"></i>
              </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations basiques
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Titre du produit
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="Product name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Catégorie
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={category_level_one?._id}
                  onChange={(e) => {
                    const _cat = CategoryData?.results?.find(cat => cat?._id === e.target.value);
                    setCategorylevelone(_cat)
                  }}
                  >
                    <option value={''}>Selectionner</option>
                    {CategoryData?.results?.length ? <>
                      {CategoryData?.results.map((cat, i) => {
                        return <option value={cat._id}>{cat.name}</option>
                      })}
                    
                    </>: <></>}
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Sous catégorie
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={category_level_two?._id}
                  onChange={(e) => {
                    const _cat = category_level_one?.category_level_two?.find(cat => cat?._id === e.target.value);
                    setCategoryleveltwo(_cat)
                  }}
                  >
                    <option value={''}>Selectionner</option>
                    {category_level_one?.category_level_two?.length ? <>
                      {category_level_one?.category_level_two.map((cat, i) => {
                        return <option value={cat._id}>{cat.name}</option>
                      })}
                    
                    </>: <></>}
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Sous Catégorie 2
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={category_level_three?._id}
                  onChange={(e) => {
                    const _cat = category_level_one?.category_level_three?.find(cat => cat?._id === e.target.value);
                    setCategorylevelthree(_cat)
                  }}
                  >
                    <option value={''}>Selectionner</option>
                    {category_level_one?.category_level_three?.length ? <>
                      {category_level_one?.category_level_three.map((cat, i) => {
                        return <option value={cat._id}>{cat.name}</option>
                      })}
                    
                    </>: <></>}
                  </select>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Marque
                  </label>
                  <AsyncSelect
                    isMulti={false}
                    name="book_club_id"
                    className='basic-multi-async-select'
                    classNamePrefix='select'
                    value={brand}
                    placeholder="Rechercher"
                    loadOptions={loadOptions}
                    isClearable={false}
                    defaultOptions={true}
                    onChange={(options) => {
                      setbrand(options);
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Modèle
                  </label>
                  <input
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="x225"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    disabled = {editMode}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Prix initial
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="10 000"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations promotionnelles
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Promotion
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={promotion.active ? 1 : 0}
                  onChange={(e) => setPromotion({...promotion, active: Number(e.target.value) === 1 ? true: false})}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Prix promotionnel
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="1 500"
                    value={promotion.cost}
                    onChange={(e) => setPromotion({...promotion, cost: e.target.value})}
                  />
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Date de début
                  </label>
                  <input
                    type="datetime-local"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    value = {moment(dateToLocalString(promotion.start_dt), 'DD/MM/YYYY hh:mm').format("YYYY-MM-DD[T]HH:mm")}
                    onChange={(e) =>setPromotion({...promotion, start_dt: onChangeDate('start', e)})}
                  />
                </div>
              </div>

              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Date de fin
                  </label>
                  <input
                    type="datetime-local"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    value = {moment(dateToLocalString(promotion.end_dt), 'DD/MM/YYYY hh:mm').format("YYYY-MM-DD[T]HH:mm")}
                    onChange={(e) => setPromotion({...promotion, end_dt: onChangeDate('end', e)})}
                  />
                </div>
              </div>

              
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations sur l'état
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   Etat
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={active ? 1 : 0}
                  onChange={(e) => setActive(Number(e.target.value) === 1 ? true: false)}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   Statut
                  </label>
                  <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={'in-stock'}>En stock</option>
                    <option value={'out-stockage'}>En rupture de stock</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Quantité
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder="1 500"
                    value={stock}
                    onChange={(e) => setStock(e.target.value)}
                  />
                </div>
              </div>

              
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Plus de détails
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   Spécifications du produit
                  </label>
                  <ReactQuill
                    maxLength = {500}
                    modules={modules}
                    formats={formats}
                    value={specifications}
                    defaultValue={specifications}
                    onChange={specificationsChange} />
                </div>
              </div>
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Description large
                  </label>
                  <ReactQuill
                    ref={quillRef}
                    modules={modulesDescription}
                    formats={formats}
                    onChange={descriptionChange} 
                    value = {description}
                    />  
                </div>
              </div>
              
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Informations complementaires
            </h6>
            <div className="flex flex-wrap">
              <div className="mt-2 grid grid-cols-4 gap-5">

              {images &&
              images.map((i) => (
                <span className="relative inline-block h-40 w-40 overflow-hidden">
                  
                    <img
                      key={i?.url}
                      src={i?.url}
                      alt="avatar"
                      className="h-full w-full object-cover "
                    />
                    
                    { (images.length > 1) ? <span 
                    onClick={() => removeImage(i)}
                    className="absolute shadow top-3 right-3 w-6 h-6 bg-red-600 rounded-full cursor-pointer flex items-center justify-center" >
                      <i className="fa-regular text-white fa-circle-xmark"></i>
                    </span>: <></>}
                 
                </span>
              ))}

                <label htmlFor="uploadFile1" className="bg-white text-gray-500 p-3 font-semibold text-base rounded w-40 h-40 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto font-[sans-serif]">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-11 mb-2 fill-gray-500" viewBox="0 0 32 32">
                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" data-original="#000000" />
                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" data-original="#000000" />
                  </svg>
                  {loadImageUpload ? <TrophySpin color="#001b97" size="small" text="" textColor="" /> :  'Upload file'}
                  <input type="file" id="uploadFile1" className="hidden" 
                    onChange={handleFileInputChange}/>
                  <p className="text-xs text-center font-medium text-gray-400 mt-2">PNG, JPG SVG, WEBP, and GIF are Allowed.</p>
                </label>

              </div>
            </div>
          </form>

          <div className="text-center flex justify-end mt-6">
            {editMode && <button 
            disabled={deleteItem?.isPending}
            onClick={() => !deleteItem.isPending && deleteItem.mutate()}
            className="bg-[#a80404] text-white active:bg-[#e24444d0] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {deleteItem?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : 'Supprimer'}
            </button>}

            <button 
            disabled={mutation?.isPending
                ||
                (

                  title.trim() === "" ||
                  description.trim() === "" ||
                  !category_level_one ||
                  price === 0 ||
                  stock === 0 ||
                  images.length === 0 ||
                  (promotion.active && promotion.cost >= price)
                )
            }
            onClick={() => !mutation.isPending && mutation.mutate()}
            className="bg-[#001b97] text-white active:bg-[#001c97db] font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                {mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : (editMode ? 'Sauvegarder' : 'Enregister')}
            </button>
        </div>

        </div>
      </div>
    </>
  );
}
